@import '../variables';

.traffic-widget{
    height: 100%;
    width: 100%;
    padding: 0;
    margin: 0;


    .traffic-widget__card {

        .traffic-widget__card-header {
            height: 50px;
            // border: none;
            background: transparent;
            border-bottom: 2px solid #FEDF33;
            display: flex;
            align-items: center;

            .traffic-widget__card-header--dropdown {
                flex-grow: 1;

                .dropdown {
                    .dropdown-toggle {

                        color: #2096F3 !important;
                        font-size: 16px;
                        &:hover, &:focus{
                            border: #2096F3 1px solid;
                        }


                    }

                    .dropdown-menu {
                        margin-top: 3px !important;
                        margin-left: 3px !important;
                        .dropdown-item {

                        }
                    }
                }


            }

            .traffic-widget__card-header--heads {
                flex-shrink: 0;
                display: flex;
                align-items: center;
                width: 180px;
                // background: red;
                font-size: 16px;
                font-weight: bold;

                .traffic-widget__card-header--head-impressions {
                    padding: 0 5px;
                    color: #18A57D;
                }

                .traffic-widget__card-header--head-view {
                    padding: 0 5px;
                    color: #0921ED;
                }

                .traffic-widget__card-header--head-leads {
                    padding: 0 5px;
                    color: #BA0611;
                }

            }
        }

        .traffic-widget__card-ul {
            height: 309px;
            overflow-y: scroll;

            .traffic-widget__card-ul--li {
                display: flex;
                align-items: center;
                
               
                .traffic-widget__card-ul--li-title {
                    flex-grow: 1;
                    color: #000;
                }

                .traffic-widget__card-ul--li-data {
                    flex-shrink: 0;
                    display: flex;
                    align-items: center;
                    width: 180px;
                    // background: #FEDF33;

                    .traffic-widget__card-ul--li-data--impressions {
                        padding: 0 20px 0 40px;
                        color: #18A57D;
                        flex-grow: 1;
                    }

                    .traffic-widget__card-ul--li-data--view {
                        padding: 0 20px;
                        color: #0921ED;
                        flex-shrink: 0;
                    }
                    
                    .traffic-widget__card-ul--li-data--leads {
                        padding: 0 20px;
                        color: #BA0611;
                        flex-shrink: 0;
                    }
                }

            }
        }
    }

}