@import '../variables';

@mixin multi-select-main-mixin {

    mdb-select * {
        font-size: .8rem;
    }

    mdb-select.mdb-select-outline.validate-success.ng-valid.ng-touched div.multiple, mdb-select.mdb-select-outline.validate-success.ng-valid.ng-touched div.single{
        box-shadow: inset 0 0 0 0px #00c851!important;
    }
    mdb-select.mdb-select-outline.validate-error.ng-invalid.ng-touched div.multiple, mdb-select.mdb-select-outline.validate-error.ng-invalid.ng-touched div.single{
        box-shadow: inset 0 0 0 0px #f44336!important;
    }

    mdb-select>div>div.multiple>div.option {
        margin-left: 7px;
    }
    
}