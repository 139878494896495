/*
// .site
*/
@import '../variables';


.site {
    min-height: 100%;
    display: flex;
    flex-direction: column;
}
.site__header {
    flex-shrink: 0;
    height: $header-height !important;
}
.site__body {
    flex-grow: 1;
    background: $light-color;

    // margin-top: $header-height;
}
@media (max-width: 575px) {
    .site__body {
       // width: fit-content;
       height: auto;
    }
    .site {
        display: inline-block;
    }
}
@media (max-width: 768px) {
    // .site__body {
    //     height: 80vh;
    // }
    .site {
        width:99.8%;
    }
}
.site__footer {
    flex-shrink: 0;
}
.icon {
    background-size: contain;
    cursor: pointer;
    display: inline-block;
}
.icon.direction {
    margin-right: .7em;
    height: 30px;
    width: 30px;
    background-image: url(/assets/images/preview-post/map.svg);
}
.icon.waze {
    margin-right: .7em;
    height: 30px;
    width: 30px;
    background-image: url(/assets/images/preview-post/waze.svg);
}