@import '../variables';
@import '../mixins/direction';

.mobile-side-header {
    // width: 100%;
    height: $header-height;
    background: $accent-color;
    // display: flex;
    // flex-direction: row;
}

.mobile-site-header__container {
    display: flex;
    align-items: center;
    height: 100%;
    width: 100%;
    padding:0px 20px 0px 10px;
}

.mobile-site-header__container--sidenav {
    flex-shrink: 0;
}

.mobile-site-header__container--logo {
    flex-shrink: 0;
}

.mobile-site-header__container--search-icon {
    flex-grow: 1;
    z-index: 1;
    padding-right: 5px;
}

.mobile-site-header__container--messages-icon {
    flex-shrink: 0;
    padding: 0px 3px;
    position: relative;

    .mobile-site-header__counter{
        position: absolute;
        top: -10px;
        left: 14px;
        background: $header-counter-background;
        border-radius: 100%;
        color: #fff;
        font-size: 12px;
        text-align: center;
        width: $header-counter-size;
        height: $header-counter-size;
    }
}

.mobile-site-header__container--dropdown {
    flex-shrink: 0;
    padding: 0px 10px;
}