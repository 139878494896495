@import '../variables';

.addOns {
    display: flex;
    flex-direction: column;
    margin: 40px 0px;
    width: 100%;

    .addOns__title {
        h5 {
            font-size: 1.2rem;
            font-weight: 700 !important;
            /* color: black; */
            text-align: center;
        }
    }

    .addOns__body {
        display: flex;
        flex-direction: column;
        margin: 10px 0px;

        .addOns__body--item {
            display: flex;
            flex-direction: column;
            padding: 10px;
            margin: 5px 0px;
            border: #DEDEDE 1px solid;
            width: 100%;

            .addOns__body--item-category {
                display: flex;
                align-items: center;
                flex-wrap: wrap;

                .addOns__body--item-category--title {
                    // flex-grow: 1;
                    font-weight: 600;
                }

                .addOns__body--item-category--link {
                    a{
                        color: #1475FC;
                        text-decoration: underline;
                    }
                }

            }

            .addOns__body--item-options {

                display: flex;
                align-items: center;
                justify-content: space-between;
                flex-wrap: wrap;
                margin: 15px 40px 0 0;

                .form-check {

                    .form-check-input.with-gap { }

                    .form-check-label {}

                }


            }

        }
    }

}