@import '../variables';
@import '../mixins/direction';


.website-home-page {    

    // New User
    .website-home-page__new-user {
        text-align: center;
        .website-home-page__new-user--greetings {
            // margin: 5px;
            font-size: 24px;
            font-weight: bold;
            align-items: center;
        }

        .website-home-page__new-user--icon {
            width: 100%;
            display: flex;
            flex-direction: column;
            align-items: center;

            svg {
                fill: $light-opposite-muted-color;
            }

            p {
                margin-top: 18px;
                color: $light-opposite-muted-color;
                font-size: 24px;
                font-weight: $font-weight-normal;
            }
        }

        .website-home-page__new-user--btns {
    
            .website-home-page__new-user--btns-primary {
                margin-top: 40px;
                margin-bottom: 14px;
            }
        }

        /* user-dashboard-info-box */
        .user-dashboard-info-box .candidates-list .thumb {
            margin-right: 20px;
        }
        .user-dashboard-info-box .candidates-list .thumb img {
            width: 80px;
            height: 80px;
            -o-object-fit: cover;
            object-fit: cover;
            overflow: hidden;
            border-radius: 50%;
        }

        .user-dashboard-info-box .candidates-list td {
            vertical-align: middle;
            width: 100%;
        }

        .user-dashboard-info-box td li {
            margin: 0 4px;
            
            
        }

        .user-dashboard-info-box{
            border: 1px solid #999;
        }

        .table.manage-candidates-top {
            table-layout: fixed;
            height: 500px;
            overflow:auto;
            display:block;
        }

        .table.manage-candidates-top tr{
            border: 2px solid #999;
        }

        .user-dashboard-info-box .candidate-list-details ul {
            color: #969696;
        }

        /* Candidate List */
        .candidate-list {
            background: #ffffff;
            display: -webkit-box;
            display: -ms-flexbox;
            display: flex;
            border-bottom: 1px solid #eeeeee;
            -webkit-box-align: center;
            -ms-flex-align: center;
            align-items: center;
            padding: 20px;
            -webkit-transition: all 0.3s ease-in-out;
            transition: all 0.3s ease-in-out;
        }

        .candidate-list .candidate-list-image {
            margin-right: 25px;
            -webkit-box-flex: 0;
            -ms-flex: 0 0 80px;
            flex: 0 0 80px;
            border: none;
        }
        .candidate-list .candidate-list-image img {
            width: 80px;
            height: 80px;
            -o-object-fit: cover;
            object-fit: cover;
        }

        .candidate-list-title {
            margin-bottom: 5px;
        }

        .candidate-list-details ul {
            display: -webkit-box;
            display: -ms-flexbox;
            display: flex;
            -ms-flex-wrap: wrap;
            flex-wrap: wrap;
            margin-bottom: 0px;
        }
        .candidate-list-details ul li {
            margin: 5px 10px 5px 0px;
            font-size: 13px;
        }

        .candidate-list .candidate-list-favourite-time {
            margin-left: auto;
            text-align: center;
            font-size: 13px;
            -webkit-box-flex: 0;
            -ms-flex: 0 0 90px;
            flex: 0 0 90px;
        }
        .candidate-list .candidate-list-favourite-time span {
            display: block;
            margin: 0 auto;
        }
        .candidate-list .candidate-list-favourite-time .candidate-list-favourite {
            display: inline-block;
            position: relative;
            height: 40px;
            width: 40px;
            line-height: 40px;
            border: 1px solid #eeeeee;
            border-radius: 100%;
            text-align: center;
            -webkit-transition: all 0.3s ease-in-out;
            transition: all 0.3s ease-in-out;
            margin-bottom: 20px;
            font-size: 16px;
            color: #646f79;
        }
        .candidate-list .candidate-list-favourite-time .candidate-list-favourite:hover {
            background: #ffffff;
            color: #e74c3c;
        }

        .candidate-banner .candidate-list:hover {
            position: inherit;
            -webkit-box-shadow: inherit;
            box-shadow: inherit;
            z-index: inherit;
        }

        .bg-white {
            background-color: #ffffff !important;
        }
        .p-4 {
            padding: 1.5rem!important;
        }
        .mb-0, .my-0 {
            margin-bottom: 0!important;
        }
        .shadow-sm {
            box-shadow: 0 .125rem .25rem rgba(0,0,0,.075)!important;
        }
    }

    // top of dashboard button positionning
    .dashboaard-button{
        display:flex;
        flex-wrap: wrap;
        justify-content: center;
        margin:0px 20px;
    }

    // existing User
    .website-home-page__existing-user {

        display: grid;
        // column-gap: 35px;
        // row-gap: 31px;
        grid-gap: 10px;
        grid-template-columns: 350px 100px 1fr;
        grid-template-rows: 1fr 418px 359px;

        .website-home-page__existing-user--name {
            grid-column: 1 / 4;
            grid-row: 1;

            font-size: 24px;
            font-weight: bold;
        }

        .website-home-page__existing-user--profile {
            grid-row: 2;
            grid-column: 1/1;
        }
        .website-home-page__existing-user--inquiries {
            grid-row: 2;
            grid-column: 2/4;
        }

        .website-home-page__existing-user--traffic {
            grid-row: 3;
            grid-column: 1/3;
        }

        .website-home-page__existing-user--yPoints {
            background: #fff;
            border-radius: 10px;
            box-shadow: 5px 5px 15px rgba($color: #000000, $alpha: .2);
            grid-row: 3;
            grid-column: 3/4;
        }


    }


    @include media-breakpoint-down(md) {

        .website-home-page__existing-user {
            grid-template-columns: repeat(3, auto);
            grid-template-rows: 60px repeat(4, 1fr);

            .website-home-page__existing-user--profile{
                grid-column: 1/4;
                grid-row: 2;
            }
            .website-home-page__existing-user--inquiries{
                grid-row: 3;
                grid-column: 1/4;
            }

            .website-home-page__existing-user--traffic{
                grid-row: 4;
                grid-column: 1/4;
            }
            .website-home-page__existing-user--yPoints{
                grid-row: 5;
                grid-column: 1/4;
            }
        }
    }

    // dashboard tables
    
    // main dashboard table container
    .dashboard-table-container {
        display: flex;
        flex-wrap: wrap;
    }

    @media (max-width: 800px) {
        .flex-container {
          flex-direction: column;
        }
    }
    
    // post approval dashboard table
    .post-approval-dashboard-table{
        flex: 1 0 20%;
        margin: 10px;
    } 

    // profile approval dashboard table
    .profile-approval-dashboard-table{
        flex: 1 0 20%;
        margin: 10px;
    } 

    // services dashboard table
    .services-dashboard-table{
        flex: 1 0 40%;
        margin: 5px;
    } 

    // jobs dashboard table
    .jobs-dashboard-table{
        flex: 1 0 40%;
        margin: 5px;
    } 
    // buy dashboard table
    .buy-dashboard-table{
        flex: 1 0 40%;
        margin: 5px;
    } 
    // sell dashboard table
    .sell-dashboard-table{
        flex: 1 0 40%;
        margin: 5px;
    } 

    .btn {
        text-transform: unset !important;
      }

}


