@import '../variables';
@import '../mixins/preloader';


.create-new-company-form{

    .preloader{
        position: absolute;
        z-index: 2;
        left: 0;
        top: 0;
        right: 0;
        bottom: 0;
        background: rgba($color: #fff, $alpha: .9);
        opacity: 0;
        visibility: hidden;
        transition: opacity .3s, visibility 0s .3s;
    }
    &.preloader-trigger .preloader{
        transition-delay: 0.5s, 0.5s;
        opacity: 1;
        visibility: visible;
        display: flex;
        align-items: center;
        justify-content: center;
        height: 100%;
    }

}

.new-company-form.form-row{
    // background: chartreuse;

    mdb-select {
        margin-top: 25px !important;
        margin-right: 10px !important;
    }

    mdb-select>div>div.single{
        height: 20px !important;
     
    }

   
}


