@import '../variables';

.gallery__card {
    position: relative;
    border: #ebebeb 1px solid;
}


.gallery__card-badge {
    position: absolute;
    background-color: $accent-color;
    color: $accent-opposite-color;
    font-size: 11px;
    font-weight: $font-weight-medium;
    text-transform: uppercase;
    padding: 4px 8px 2px;
    top: -2px;
    right: .75rem;
    border-bottom-right-radius: 2px;
    border-bottom-left-radius: 2px;
}