@import '../variables';

.post-summary {

    display: flex;
    // align-items: center;
    flex-wrap: wrap;
    margin: 40px 0px;
    font-size: .8rem;
    .post-summary__details{
        // background: chocolate;
        // flex-grow: 1;
        margin-bottom: 40px;
        // background: yellow;
        flex-grow: 1;
        margin: 0;
        // padding: 0;

        .post-summary__details--title{
            
            h5{
                font-weight: bold;
                font-size: 1rem;
            }
        }

        .post-summary__details--body{
            display: flex;
            align-items: center;
            flex-wrap: wrap;
          

            .post-summary__details--body-immediately{
                flex-grow: 0.1;
                .form-check{
                    padding-left: 0px !important;

                    label.form-check-label{
                        padding-left: 23px !important;
                        // margin-right: 50px;
                    }
                }
            }

            .post-summary__details--body-schedul{

                display: flex;
                align-items: center;

                .post-summary__details--body-later{
                    flex-shrink: 0;
                    .form-check{
                        padding-left: 0px !important;
    
                        label.form-check-label{
                            padding-left: 23px !important;
                            // margin-right: 50px;
                        }
                    }
                }
    
                .post-summary__details--body-datepicker{
                    margin-right: 30px;
                    // flex-shrink: 0;
                    border: 1px solid #B1B1B1;
                    display: flex;
                    align-items: center;
                    padding: 0 10px;
                    // margin-bottom: 20px;
    
                    app-icon{
                        cursor: pointer;
                        padding-right: 10px;
                    }
    
                    .md-form {
                        margin: 0px !important;
    
                        input.form-control{
                            border-bottom: none !important;
                            margin: 0px !important;
                        }
                    }
                }


            }
        }
    }

    .post-summary__summary{
        // background: cornflowerblue;
        // flex-shrink: 0;
        width: 350px;
        border: 1px solid #C7C7C7;
        margin-top: 10px;

        .post-summary__summary--header{
            width: 100%;
            height: 50px;
            border-bottom: 1px solid #C7C7C7;
            background: #F7F7F7;
            padding: 10px;
            font-size: 18px;
            font-weight: bold;
            color: #000;
        }

        .post-summary__summary--body{
          
            padding: 10px;
            display: flex;
            flex-direction: column;

            .post-summary__summary--body-details{
                margin-bottom: 20px;
                // flex-grow: 1;

                .post-summary__summary--body-details--item{
                   height: 30px;
                    width: 100%;
                    display: flex;
                    align-items: center;

                    .post-summary__summary--body-details--item-text{
                        flex-grow: 1;
                    }

                    .post-summary__summary--body-details--item-amount{
                        flex-shrink: 0;
                    }
                }
            }

            .post-summary__summary--body-divider{
                height: 1px;
                background: #C7C7C7;
                width: 90%;
                margin: 0 auto;
            }

            .post-summary__summary--body-subtotal{
                display: flex;
                align-items: center;
                height: 50px;
                font-weight: 400;
                .post-summary__summary--body-subtotal--title{
                    flex-grow: 1;
                }
                .post-summary__summary--body-subtotal--amount{
                    flex-shrink: 0;
                }
            }

            .post-summary__summary--body-total{
                display: flex;
                align-items: center;
                height: 50px;
                font-weight: bold;
                .post-summary__summary--body-total--title{
                    flex-grow: 1;
                }
                .post-summary__summary--body-total--amount{
                    flex-shrink: 0;
                }

            }
        }

    }

}

@include media-breakpoint-down(sm) {
    .post-summary{
        .post-summary__details{
            .post-summary__details--body{
                // flex-direction: column;
            }
        }
    }
}