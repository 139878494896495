@import '../variables';



.pricing-package{

    // background: chartreuse;
    width: 100%;
    // border: 1px #000 solid;
    font-size: .7rem;
    font-weight: 400;
    color: #000;
    display: flex;
    justify-content: center;
    // flex-wrap: wrap;
    margin: 40px 0px;
    
    

    .preloader{
        position: absolute;
        z-index: 2;
        left: 0;
        top: 0;
        right: 0;
        bottom: 0;
        background: rgba($color: #fff, $alpha: .9);
        opacity: 0;
        visibility: hidden;
        transition: opacity .3s, visibility 0s .3s;
    }
    &.preloader-trigger .preloader{
        transition-delay: 0.5s, 0.5s;
        opacity: 1;
        visibility: visible;
        display: flex;
        align-items: center;
        justify-content: center;
        height: 100%;
    }


    .pricing-package__item{

        // background: chocolate;
        // border: cyan 1px solid;
        // width: 100%;
        margin: 0px 5px 5px 0px;
        display:flex;
    

        .form-check.pricing-package__form-check{
            padding: 0px !important;
            margin: 0px !important;

            .form-check-input.with-gap.pricing-package__form-check-input {
                margin: 0px !important;
                padding: 0px !important;
                visibility: hidden !important;
            }

            .form-check-label.pricing-package__form-check-label{

                margin: 0px !important;
                padding: 0px !important;
                display: flex;
                height: auto;

                &::before{
                    visibility: hidden !important;
                }

                &::after{
                    visibility: hidden !important;
                }

                .pricing-package__card{
                    position: relative;
        background-color: #fff;
        border-radius: 6px;
        border: 1px solid #efefef;
        box-shadow: 0 0 2px 0 #efefef;
        min-height: 300px;
        display: flex;
        flex-direction: column;
                    width: 100%;

                    &.choosed{
                        opacity: .4;
                    }

                    // &.gold-border{border: 1px solid #CA934D;}
                    // &.silver-border{border: 1px solid #575757;}
                    // &.bronze-border{border: 1px solid #986464;}

                   
                    .pricing-package__card--header{

                        .pricing-package__card--header-type{
                            &.gold-color{ background: #ffffff;
                                .merchant-badge {
                                    display: flex;
    border-radius: 4px;
    justify-content: center;
    align-items: center;
    font-size: .7em;
    line-height: .715em;
    font-weight: 700;
    padding: 0 1em 0 .1em;
                                }
                                .merchant-badge .icon{
                                    height: 40px!important;
                                    width: 40px;
                                background-color: #dd9918;
                                -webkit-mask-size:40px;
                                }
                            }
                            &.silver-color{ background: #ffffff;
                                .merchant-badge .icon{
                                    height: 40px!important;
                                    width: 40px;
                                background-color: #4b7fb4;
                                -webkit-mask-size:40px;
                                }
                            }
                            &.bronze-color{ background: #ffffff;
                            
                                .merchant-badge .icon{
                                    height: 40px!important;
                                    width: 40px;
                                background-color: #ba5e44;
                                -webkit-mask-size:40px;
                                }}
                           
                            .pricing-package__card--header-type--title{
                                color: #444;
                                text-align: center;
                                font-size: 1.4rem;
                                padding: 10px;
                                font-weight: 700;
                                border-bottom: 1px solid #efefef;
                                margin: .5em 1em;
                            }
                        }

                        .pricing-package__card--header-price{

                            position: relative;
                            display: flex;
                            align-items: center;
                            font-size: 2rem;
                            font-weight: 300;
                            justify-content: center;
                            padding: 20px 0px;
                            margin-bottom: 5px;

                            .pricing-package__card--header-price--amount{
                                font-size: 2.6rem;
                                font-weight: 600;
                                padding: 0 0.05em;
                            }
                            .pricing-package__card--header-price--amount-currency{
                                font-size: 0.8rem;
                                // align-self: flex-start;

                                position: relative;
                                top: -10px;
                                // left: 85px;
                            }
                            .pricing-package__card--header-price--amount-duration{
                                font-size: 0.8rem;
                                align-self: flex-end; 
                                position: relative;
                                bottom: -10px;
                                // position: absolute;
                                // bottom: 10px;
                                // right: 60px;
                            }
                        }
                    }

                    .pricing-package__card--body{
                        padding: 1em .5em;
                        .pricing-package__card--body-list{
                            ul{
                                list-style: none;
                                padding: 0px;
                                margin: 0px;

                                li{
                                    display: flex;
                                    align-items: center;
                                }
                            }
                            .pricing-package__card--body-list--icon{
                                margin: 0px 5px 0px 10px;
                            }
                            .pricing-package__card--body-list--text{

                            }
                        }

                        

                    }
                    .pricing-package__card--body-btn{
                        margin: 5px;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        text-align: center;
                        .yp-button{
                           width: calc(100% - 4em);

                       }
                        .pricing-package__card--body-btn-gold{
                            color: #fff !important;
                        }
                        .pricing-package__card--body-btn-silver{
                            color: #fff !important;
                        }
                        .pricing-package__card--body-btn-bronze{
                            color: #fff !important;
                        }
                    }

                }

            }

        }

    }

}
@media only screen and (max-width: 768px){
    .pricing-package__card{

        width: 255px!important;
    }
}

@media only screen 
  and (min-device-width: 1024px) 
  and (max-device-width: 1366px) 
  and (-webkit-min-device-pixel-ratio: 2)
  {
    .pricing-package__card{

        width: 300px!important;
    }
  }

  .pricing-package__card{
    position: relative;
    background-color: #fff;
    border-radius: 6px;
    border: 1px solid #efefef;
    box-shadow: 0 0 2px 0 #efefef;
    min-height: 300px;
    display: flex;
    flex-direction: column;
  }
  
  .pricing-package__card .pricing-package__card--header .merchant-badge{
    position: absolute;
    left: 1.5em;
    top: -1px;
    background-color: initial;
  }
  
  .pricing-package__card .pricing-package__card--header .merchant-badge .icon{
      height: 40px;
      width: 40px;
    -webkit-mask: url('/assets/images/download.svg') no-repeat;
  }




// .pricing-package{

//     width: 100%;
//     display: flex;

//     .pricing-package__form-check{
//         .pricing-package__form-check-input{

//         }

//         .pricing-package__form-check-label{

//             // position: relative;
//             padding-left: 0px !important; 
//             cursor: pointer;
//             /* display: inline-block; */
//             // height: 1px !important; 
//             // line-height: 0 !important;
//             transition: 0.28s ease;
//             -webkit-user-select: none;
//             -moz-user-select: none;
//             user-select: none;

//             .pricing-package__card{

//                 margin: 20px 20px 20px 0px;
//                 min-width: 270px;

//                 &.choosed{
//                     opacity: .3;
//                 }
        
//                 &.gold-border {
//                     border: 1px solid #CA934D;
//                 }
        
//                 &.silver-border {
//                     border: 1px solid #575757;
//                 }
        
//                 &.bronze-border {
//                     border: 1px solid #986464;
//                 }
        
//                 .pricing-package__card--header{
//                     width: 100%;
                   
//                     .pricing-package__card--header-type {
//                         width: 100%;
//                         height: 50px;
//                         position: relative;
        
//                         &.gold-color {
//                             background: #CA934D;
//                         }
            
//                         &.silver-color {
//                             background: #575757;
//                         }
            
//                         &.bronze-color {
//                             background: #986464;
//                         }
//                     }
        
//                     .pricing-package__card--header-type--title {
//                         height: 100%;
//                         display: flex;
//                         align-items: center;
//                         justify-content: center;
//                         font-size: 22px;
//                         font-weight: bold;
//                         color: #fff;
//                     }
        
//                     .pricing-package__card--header-price {
//                         width: 100%;
//                         height: 50px;
//                         display: flex;
//                         align-items: center;
//                         justify-content: center;
        
        
                       
//                         .pricing-package__card--header-price--amount {
//                             position: relative;
//                             color: #000;
//                             font-size: 26px;
//                             font-weight: bold;
        
//                             .pricing-package__card--header-price--amount-currency {
//                                 position: absolute;
//                                 top: 4px;
//                                 left: -18px;
//                                 font-size: 12px;
//                                 color: #575757;
//                                 font-weight: lighter;
            
//                             }
        
//                             .pricing-package__card--header-price--amount-duration {
//                                 position: absolute;
//                                 bottom: 2px;
//                                 right: -42px;
//                                 font-size: 12px;
//                                 color: #575757;
//                                 font-weight: lighter;
//                             }
//                         }
                      
//                     }
//                 }
        
//                 .pricing-package__card--body {
//                     .pricing-package__card--body-list {
//                         ul {
//                             list-style: none;
//                             width: 100%;
//                             // background: blue;
//                             margin: 0px;
//                             padding-left: 5px;
        
//                             li{
//                                 // background: chartreuse;
//                                 font-size: 13px;
//                                 font-weight: 400;
//                                 height: 30px;
//                                 display: flex;
//                                 align-items: center;
//                                 // border: 1px #000 solid;
        
//                                 .pricing-package__card--body-list--icon {
//                                     margin-right: 4px;
//                                 }
        
//                                 .pricing-package__card--body-list--text {
                                    
//                                 }
//                             }
//                         }
//                     }
        
//                     .pricing-package__card--body-btn {
//                         display: flex;
//                         align-items: center;
//                         justify-content: center;
//                         padding: 5px;
                       
//                         .btn.btn-sm.pricing-package__card--body-btn-gold {
//                             background: #CA934D !important;
//                             color: #fff !important;
//                             font-size: 16px !important;
//                         }
        
//                         .btn.btn-sm.pricing-package__card--body-btn-silver {
//                             background: #575757 !important;
//                             color: #fff !important;
//                             font-size: 16px !important;
//                         }
        
//                         .btn.btn-sm.pricing-package__card--body-btn-bronze {
//                             background: #986464 !important;
//                             color: #fff !important;
//                             font-size: 16px !important;
//                         }
//                     }
//                 }
//             }



//             &::before{
//                 display: none !important;
//             }

//             &::after{
//                 display: none !important;
//             }
        

//         }
//     }

   
// }

// @include media-breakpoint-down(sm){
   
//     .pricing-package {
//         display: flex;
//         flex-direction: column;

//         .pricing-package__card {
//             width: 100%;
//             margin: 10px auto;
//         }
//     }
// }