/*
// .site-header
*/
@import '../variables';
@import '../mixins/direction';

.site-header {
    color: $header-font-color;
    background: $header-bg;
    height: $header-height;
    padding: 0px;
    margin: 0px;
}

.site-header__row{
   height: 100%;
   width: 100%;
   display: flex;
   align-items: center;
}

.site-header__left {
    width: 160px;
    flex-shrink: 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 100%;
    padding-left: 50px;
    .site-header__logo {
        flex-grow: 1;
    }

}

.site-header__right {
    height: 100%;
    width: 100%;
    // padding-left: 63%;
    display: flex;
    align-items: center;
}

.site-header__search {
    flex-grow: 1;
    z-index: 1;
    margin-left: $header-search-margin-left;
}


.site-header__link {
    margin: $header-link-margin;
    padding: $header-link-padding;

    a {
        font-size: $header-link-font-size;
        font-weight: $font-weight-normal;

        &:not([href]):not([tabindex]):hover {
            text-decoration: underline !important;
        }
    }


}

.site-header__icons {
    margin: $header-icon-margin;
    padding-right: $header-icon-padding;
    position: relative;
       
    .site-header__counter {
        content: "";
        background: $header-counter-background;
        color: $header-counter-color;
        font-weight: $font-weight-light;
        display: flex;
        align-items: center;
        justify-content: center;
        position: absolute;
        top: -10px;
        left: 14px;
        width: $header-counter-size;
        height: $header-counter-size;
        border-radius: 100px;
        font-size: $header-counter-font-size;
        padding: $header-counter-padding;
    }

}

.site-header__user-avatar {
    margin: $header-user-avatar-margin;
    padding: $header-user-avatar-padding;
}

// // Media Query

@include media-breakpoint-up(lg) {

    .site-header__left{
        width: 310px;
    }

  }

