@import '../variables';

.address-card {
    position: relative;
    border: #ebebeb 1px solid;
}

.address-card__badge {

    position: absolute;
    background-color: $accent-color;
    color: $accent-opposite-color;
    font-size: $yp-font;
    font-weight: $font-weight-medium;
    text-transform: uppercase;
    padding: 4px 8px 2px;
    top: -2px;
    right: .75rem;
    border-bottom-right-radius: 2px;
    border-bottom-left-radius: 2px;

}
.address-card__badge--muted {
    background-color: #f0f0f0;
    color: $light-opposite-color;
}

.address-card__body {
    padding: 1.5rem;
    font-size: $yp-font;
    line-height: 18px;
}

.address-card__name {
    font-size: $yp-font;
    font-weight: $font-weight-bold;
    margin-bottom: 1.125rem;
}

.address-card__row + .address-card__row {
    margin-top: .75rem;
}

.address-card__row-title {
    font-size: $yp-font;
    color: $light-opposite-muted-color;
}
.address-card__row-content {}

.address-card__footer {
    margin-top: 1.625rem;
    color: $link-color;
}

.address-card--featured {
    .address-card__body {
        padding: 2rem;
    }

    .address-card__name {
        font-weight: $font-weight-bold;
    }

    @include media-breakpoint-only(md) {
        .address-card__body {
            padding: 1.5rem;
        }
    }

    @include media-breakpoint-down(md) {
        .address-card__body {
            padding: 1.375rem;
        }
    }
}


