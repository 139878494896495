@import '../variables';

@mixin message {

    .message{
        .message__header{
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            font-size: 1rem;
            font-weight: bold;
        }
        .message__body{
    
            text-align: center;
            margin: 1rem;
    
            .url{
                margin: 25px 0px;
    
                a{
                    color: #2096F3;
                }
            }
        }
        .message__btns{
            display: flex;
            justify-content: center;
    
            .btn{
                padding: .9rem 2.5rem !important;
                font-size: .9rem !important;
            }
        }
    }
    
    
}