@import '../variables';


.inquiries-widget {
    height: 100%;
    width: 100%;
    background: #fff;
    padding: 0;
    margin: 0;

    .inquiries-widget__card {

        .inquiries-widget__card-header {

            height: 50px;
            background: #9450B5;
            color: #fff;
            display: flex;
            align-items: center;

            .inquiries-widget__card-header--icon {
                flex-shrink: 0;
                margin-right: 20px;

                svg{
                    fill: #fff;
                }
            }

            .inquiries-widget__card-header--title {
                flex-grow: 1;
                font-size: 18px;
                font-weight: bold;
            }

            .inquiries-widget__card-header--link {
                flex-shrink: 0;
                font-size: 16px;
                font-weight: normal;
                text-transform: uppercase;

                a{
                    color: #fff;
                }
            }

        }

        .inquiries-widget__card-ul {
            height: 368px;
            overflow-y: scroll;

            .inquiries-widget__card-ul--li{

                display: flex;
                align-items: center;

                svg {
                    fill: #BCBCBC;
                }

                .inquiries-widget__card-ul--li-avatar {
                    flex-shrink: 0;
                    margin-right: 20px;
                }

                .inquiries-widget__card-ul--li-title {
                    flex-grow: 1;
                    display: flex;
                    flex-direction: column;

                    .inquiries-widget__card-ul--li-title--sender {
                        font-size: 18px;
                        color: #000;
                    }

                    .inquiries-widget__card-ul--li-title--subject {
                        font-size: 16px;
                        color: #5A5A5A;
                    }

                    .inquiries-widget__card-ul--li-title--date {
                        font-size: 14px;
                        color: #B4B4B4;
                    }
                }
                
                .inquiries-widget__card-ul--li-icons {
                    flex-shrink: 0;
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    padding: 0 5px;

                    svg {
                        margin: 0 3px;
                    }

                    .stared{
                        svg{
                            fill: #FD9D27;
                        }
                    }
                }

            }

        }


    }



}