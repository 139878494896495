@import '../variables';

.create-post {
    height: 100%;
    width: 100%;
    background: #fff;
    padding: 0;
    margin: 0;

    .create-post__card {
        margin-top: 0px !important;
        box-shadow: none !important;

        // control full card body
        .create-post__card-body {

            padding: 1.25rem 1.25rem !important;

            .card-body {
                padding: 0.25rem 1.25rem 0rem 1.25rem !important;
            }

            // control full stepper
            ul.stepper {
                transition: all .1s !important;
                margin: 0em -2.2rem !important;
                padding: 0rem 1.25rem 0rem 1.25rem !important;

                .step-new-content {
                    margin-left: 27px !important;
                    margin-right: 12px !important;
                }

                // Contorl full step
                .step {
                    
                }
                
                // Contorl full step
                .step-title {
                    position: relative;
                    pointer-events: none !important;
                    
                    &::after {
                        content: '';
                        height: 1px;
                        background: #E2E2E2;
                        left: 0px;
                        width: 100%;
                        bottom: 0;
                        position: absolute;
                    }

                    &::before {
                        position: absolute;
                        content: '\276F';
                        top: 20px;
                        right: 20px;
                        transform: rotateZ(90deg);
                        font-size: 22px;
                    }

                }

                // control active step
                .step.active {
                }

                // control number befor step
                .step::before {}

                .step::after {
                    width: 0px !important;
                }
            }


        }
    }

}

.next-btn {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 20px 0px;

    .btn.btn-sm{
        padding: .5rem 3.6rem !important;
        font-size: .8rem;
    }
}
.btn-middle {
    justify-content: center;
}
@media (min-width: 768px) {
    .btn-middle button {
        padding: 0.5rem 3.6rem !important
    }
}


@media (max-width: 768px) {
    .next-btn {
        .btn.btn-sm {
            padding: 3%!important;
            width: 100px;
        }
    }
    .btn-middle button{
        min-width: 130px;
    }

}

@include media-breakpoint-down(xs) {

    .create-post {
        .create-post__card {
            .create-post__card-body{
                ul.stepper {
                    margin: 0em -2.2rem !important;
                    padding: 0 1rem !important;

                    .step-new-content {
                        margin-left: 0px !important;
                        margin-right: 0px !important;
                    }
                }
            }
        }
    }

}