@import '../variables';

@mixin local-layout($columns, $gutter) {
    .gallery__item {
        max-width: calc((100% - #{($columns - 1) * $gutter}) / #{$columns});
    }
    .gallery__divider:nth-child(2n) {
        width: $gutter;
    }
    .gallery__divider:nth-child(#{$columns * 2}n) {
        width: 100%;
        height: $gutter;
    }
}  


.gallery {
    padding: 20px 0px;
    display: flex;
    flex-wrap: wrap;
}

.gallery__item {
    flex-basis: 0;
    flex-grow: 1;
}

.gallery__item--new {
    border: 2px dashed #E9EDF2;
    border-radius: 2px;
    padding: 1rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

}

.gallery__plus {
    width: 60px;
    height: 60px;
    border: 2px dashed #ebebeb;
    position: relative;
    border-radius: 30px;
    // margin-bottom: 24px;
    transition: border-color .2s;

    &::after,
    &::before {
        content: '';
        position: absolute;
        width: 32px;
        height: 4px;
        top: calc(50% - 2px);
        transition: background-color .2s;
        background-color: #ebebeb;
        border-radius: 1.5px;
        left: calc(50% - 16px);
    }

    &::before {
        transform: rotateZ(90deg);
    }
}

.gallery__item--new:hover .gallery__plus {
    border-color: #e0e0e0;

    &::after,
    &::before {
        background-color: #dbdbdb;
    }
}

.gallery__divider:last-child {
    display: none;
}



@include media-breakpoint-up(xl) {
    @include local-layout(6, 8px);
}

@include media-breakpoint-between(md, xl) {
    @include local-layout(6, 6px);
}

@include media-breakpoint-between(xs, md) {
    @include local-layout(4, 6px);
}

@include media-breakpoint-down(xs) {
    @include local-layout(2, 6px);
}