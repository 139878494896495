@import '../variables';

.profile-widget {
   

    
    .profile-widget__card {
    
        .profile-widget__card-header {
            height: 50px;
            background: #F7F7F7;
            display: flex;
            align-items: center;
            justify-content: space-between;

            span{
                font-size: 18px;
                font-weight: bold;
            }

            a{
                text-transform: uppercase;
                font-size: 16px;
                font-weight: normal;
            }
        }

        .profile-widget__card-ul {
            height: 369px;
            overflow-y: scroll;
            -webkit-overflow-scrolling: touch;
            
            .profile-widget__card-ul--li {

                display: flex;
                align-items: center;
                font-size: 16px;

                .profile-widget__card-ul--li-type {
                    height: 100%;
                    width: 2px;
                    margin-right: 4px;
                }

                .profile-widget__card-ul--li-type-individual{
                    @extend .profile-widget__card-ul--li-type;
                    background: $accent-color;
                }
                .profile-widget__card-ul--li-type-company{
                    @extend .profile-widget__card-ul--li-type;
                    background: green;
                }

                .profile-widget__card-ul--li-title{
                    margin-right: 10px;
                }
                .profile-widget__card-ul--li-class{
                    padding: 2px;
                    color: #fff;
                    font-size: 12px;
                    font-weight: bold;
                }
                .gold{
                    @extend .profile-widget__card-ul--li-class;
                    background: #CA934D;
                }
                .silver{
                    @extend .profile-widget__card-ul--li-class;
                    background: #A5A5A5;
                }
            }

            
        }


    }

}