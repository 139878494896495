@import '../variables';


@mixin preloader-trigger {

    transition-delay: 0s, 0s;
    opacity: 1;
    visibility: visible;
    
}

@mixin preloader {

    position: absolute;
    z-index: 2;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    background: rgba(#fff, .9);
    opacity: 0;
    visibility: hidden;
    transition: opacity .3s, visibility 0s .3s;
    
}

@mixin preloader-container {

    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    
}