@import '../variables';

.yPoints-widget {
    width: 100%;
    height: 100%;
    background: #fff;
    padding: 0;
    margin: 0;


    .yPoints-widget__card {

        .yPoints-widget__card-header {
            height: 50px;
            background: #FD9D27;
            display: flex;
            align-items: center;
            color: #fff;
            border: none;

            svg{
                fill: #fff;
            }

            .yPoints-widget__card-header--icon {
                flex-shrink: 0;
                margin-right: 10px;
            }

            .yPoints-widget__card-header--title {
                flex-grow: 1;
                font-size: 18px;
                font-weight: bold;
            }

            .yPoints-widget__card-header--link {
                flex-shrink: 0;
                font-size: 16px;

                a{
                    text-decoration: none;
                    color: #fff;
                }
            }
        }

        .yPoints-widget__card-ul {
            height: 309px;
            overflow-y: scroll;

            .yPoints-widget__card-ul--li{
                display: flex;
                align-items: center;
                color: #000;

                .yPoints-widget__card-ul--li-title {
                    flex-grow: 1;
                }

                .yPoints-widget__card-ul--li-points{
                    flex-shrink: 0;
                    display: flex;
                    align-items: center;
                    .yPoints-widget__card-ul--li-points--amount {
                        margin-right: 7px;
                    }

                    .yPoints-widget__card-ul--li-points--icon {
                        svg {
                            fill: #FFD949;
                        }
                    }
                }
            }
        }

    }

}