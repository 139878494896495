/*
// .mobilemenu
*/
@import '../variables';

$local-transition-duration: .3s;


.mobilemenu{
    visibility: hidden;
    position: fixed;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 1000;
    transition: visibility 0s $local-transition-duration;
}

.mobilemenu__backdrop {
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    background:#3d464d;
    opacity: 0;
    transition: opacity $local-transition-duration;
}

.mobilemenu__body {
    position: absolute;
    top: 0;
    width: 290px;
    height: 100%;
    background: #fff;
    box-shadow: 0 0 20px rgba(#000, .4) ;
    display: flex;
    flex-direction: column;
    transition: transform $local-transition-duration;
}

.mobilemenu__header{
    height: 54px + 1px;
    display: flex;
    flex-shrink: 0;
    align-items: center;
    border-bottom: 1px solid #ebebeb;
}

.mobilemenu__title {
    padding: 0 20px;
    font-weight: 700;
    flex-grow: 1;
}

.mobilemenu__close {
    border: none;
    background: transparent;
    height: 54px;
    width: 54px + 1px;
    flex-shrink: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;    
    fill: #999;
    transition: fill .2s;

    &:focus {
        outline: none;
    }

    &:focus, 
    &:hover {
        fill: #3d464d;
    }
}

.mobilemenu__content {
    flex-grow: 1;
    overflow-y: scroll;
    -webkit-overflow-scrolling: touch;
}

.mobilemenu--open {
    visibility: visible;
    transition-delay: 0s;

    .mobilemenu__backdrop {
        opacity: .5;
    }

    .mobilemenu__body {
        transform: translateX(0);
    }
}