@import '../variables';
@import '../mixins/direction';


.header-side-nav{
   
}

.header-side-nav__toggle{
    svg {

        &:hover {
            cursor: pointer;
        }
    }
}

.header-side-nav__container{
  
}

.header-side-nav__container--sidenav{
    // background: $lime-accent-2 !important;
}


.header-side-nav__container--sidenav--logo {
    height: 70px;
    padding-top: 18px;
    padding-right: 31px;
}

.header-side-nav__container--sidenav--divider{
    height: 1px;
    width: 100%;
    background: $light-opposite-muted-color;
}

.header-side-nav__container--sidenav--items{
    // height: 20px;
    margin-top: 10px;
    flex-grow: 1;
    width: 100%;
    // background: green;
}

.header-side-nav__container--sidenav--item{
    display: flex !important;
    align-items: center;
    height: 60px;
    padding: 0px 10px;
    // justify-content: space-between;
    border-bottom: $light-opposite-muted-color 1px solid;
    color: $light-opposite-color;

    .header-side-nav__container--sidenav--item-icon {
        padding-right: 5px;
    }
    .header-side-nav__container--sidenav--item-title {
        padding-left: 5px;
        position: absolute;
        margin-left: 10%;
    }

    svg {
        fill: $light-opposite-color;
    }


}