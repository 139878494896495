/*
// base
*/
@import '../variables';
@import '../mixins/direction';

html {
    height: 100%;
}

body {
    height: 100%;
    font-family: $body-font-family;
    font-size: $body-font-size;
    font-weight: $body-font-weight;
    line-height: $body-line-height;
    background: $body-bg;
    color: $body-font-color;
    backface-visibility: hidden;
    overflow-y: scroll;


    @include direction {
        text-align: $inline-start;
        direction: $direction;
    }
}


svg {
    display: block;
}

a {
    color: $link-color;
}
a:hover {
    color: $link-hover-color;
    text-decoration: none;
}


label {
    margin-bottom: .25rem;
}


h1 {
    @if ($h1-font-family != null) { font-family: $h1-font-family; }
    @if ($h1-font-size != null)   { font-size: $h1-font-size; }
    @if ($h1-font-weight != null) { font-weight: $h1-font-weight; }
    @if ($h1-font-style != null)  { font-style: $h1-font-style; }
}
h2 {
    @if ($h2-font-family != null) { font-family: $h2-font-family; }
    @if ($h2-font-size != null)   { font-size: $h2-font-size; }
    @if ($h2-font-weight != null) { font-weight: $h2-font-weight; }
    @if ($h2-font-style != null)  { font-style: $h2-font-style; }
}
h3 {
    @if ($h3-font-family != null) { font-family: $h3-font-family; }
    @if ($h3-font-size != null)   { font-size: $h3-font-size; }
    @if ($h3-font-weight != null) { font-weight: $h3-font-weight; }
    @if ($h3-font-style != null)  { font-style: $h3-font-style; }
}
h4 {
    @if ($h4-font-family != null) { font-family: $h4-font-family; }
    @if ($h4-font-size != null)   { font-size: $h4-font-size; }
    @if ($h4-font-weight != null) { font-weight: $h4-font-weight; }
    @if ($h4-font-style != null)  { font-style: $h4-font-style; }
}
h5 {
    @if ($h5-font-family != null) { font-family: $h5-font-family; }
    @if ($h5-font-size != null)   { font-size: $h5-font-size; }
    @if ($h5-font-weight != null) { font-weight: $h5-font-weight; }
    @if ($h5-font-style != null)  { font-style: $h5-font-style; }
}
h6 {
    @if ($h6-font-family != null) { font-family: $h6-font-family; }
    @if ($h6-font-size != null)   { font-size: $h6-font-size; }
    @if ($h6-font-weight != null) { font-weight: $h6-font-weight; }
    @if ($h6-font-style != null)  { font-style: $h6-font-style; }
}
.yp-button.blue {
    background-color: #147BFC;
    border: 1px solid #147BFC;
    color: #fff;
}
.yp-button.red {
    background-color: #dc3545;
    border: 1px solid #dc3545;
    color: #fff;
}
.yp-button.white {
    background-color: #fff;
    border: 1px solid #147BFC;
    color: #147BFC;
}
.yp-button.xl {
    padding: .7em 1.5em;
    font-size: 1em;
}
.yp-button.medium {
    padding: .7em 1.5em;
    font-size: 1em;
    margin-top: 20px;
    text-transform: none;
}
.yp-button.sm {
    padding: .7em 1.5em;
    font-size: 1em;
}
.yp-button.xxl {
    padding: 0.7em 5.5em;
    font-size: 1em;
}
.yp-button.full {
    padding: .55em 1.5em;
    font-size: 1em;
}
.upload-photo {
    border-color: #dcdcdc;
}
.yp-button {
    width: max-content;
    // width: 100%;
    max-width: 500px;
    padding: .5em 1em .6em;
    margin: .5em .5em 0 0;
    background-color: #eaeaea;
    color: #555;
    font-size: .9em;
    cursor: pointer;
    border: none;
    border-radius: 4px;
    display: inline-block;
    font-weight: 500;
    transition: all .2s ease-in;
    text-transform: uppercase;
}

.yp-link-button{
    background: none!important;
    border: none;
    padding: 0!important;
    text-decoration: none;
    cursor: pointer;
    width: max-content;
    // width: 100%;
    max-width: 500px;
    padding: .5em .5em;
    margin: 1.5rem 0rem 1.5rem 1rem;
    background-color: #eaeaea;
    color:#147BFC;
    font-size: .9em;
    cursor: pointer;
    border: none;
    border-radius: 4px;
    display: inline-block;
    font-weight: 500;
    transition: all .2s ease-in;
    text-transform: uppercase;

}

.font {
    font-size: $yp-font !important;
}
.family {
    font-family: $yp-family !important;
}
.yp-dropdown {
    display: flex;
    flex-direction: column;
    margin-top: 9px;
    background-color: #fff;
    border-radius: 4px;
    padding: .5em 0;
    max-height: 300px;
}
.yp-dropdown .item {
    display: block;
    cursor: pointer;
    color: #666;
    padding: .5em 2em .5em 1em;
    text-transform: capitalize;
}
.yp-h2, .yp-h4, .yp-h5 {
    font-weight: 500;
    padding: .5em 0;
}
.yp-h2 {
    color: #444;
    font-size: 1.2em;
}

.social {
    min-width: 100%;
    padding: 0 0 1em;
    display: flex;
    flex-flow: row wrap;
}
.title {
    min-width: 100%;
    flex: 1;
    color: #777;
    padding: 0 0 .5em .5em;
}
 .other-info {
    min-width: 100%;
    display: flex;
}
.views, .report {
    height: 22px;
    display: flex;
    align-items: center;
    margin-right: 1em;
    color: #999;
    margin-bottom: .3em;
}
strong {
    font-weight: bold;
}
.icon-upload {
    width: 20px;
    height: 20px;
    background-color: #444;
    mask: url(/assets/images/preview-post/upload-photo.svg) no-repeat center/contain;
}