// FORMS PRO
.form-header {
  box-shadow: $z-depth-1-half;
  color: $form-header-color;
  text-align: center;
  margin-top: $form-header-mt;
  margin-bottom: $form-header-mb;
  padding: $form-header-padding;
  border-radius: $form-header-border-radius;
}

// Input group
.md-form.input-group .input-group-text [type='checkbox'] + label,
.input-group .input-group-text [type='checkbox'] + label {
  padding-left: $input-group-text-checkbox-pl;
}
.md-form.input-group .input-group-text .filled-in[type='checkbox'] + label,
.input-group .input-group-text .filled-in[type='checkbox'] + label {
  height: $input-group-text-checkbox-height;
}
.md-form.input-group .input-group-text [type='radio'] + label,
.input-group .input-group-text [type='radio'] + label {
  padding-left: $input-group-text-radio-pl;
  height: $input-group-text-radio-height;
}
