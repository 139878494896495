@import '../variables';

.company-form {

    .company-form__form-row {

        .md-form.md-outline{
            margin-top: 0.6rem !important;
            margin-bottom: 0.6rem !important;
        }

    }

    .company-form__form-btn {
        display: flex;
        justify-content: center;
        align-items: center;

        .btn.btn-md {
            padding: .7rem 2.6rem;
            font-size: .8rem;
        }
    }

}