@import '../variables';
@import '../mixins/multi-select';
@import '../mixins/input-text';

$active-color: #1475fc;

.post-form {
  margin: 16px 0px;
  .post-form__kind {
    width: 80%;
    // padding:10px;
    margin-bottom: 16px;
    .post-form__kind--title {
      //margin-bottom: 7px;
    }
    .post-form__kind--select {
      @include multi-select-main-mixin;
    }
  }

  .post-form__categories {
    width: 80%;
    // padding: 10px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    // flex-wrap: wrap;

    .post-form__categories--category {
      width: 50%;
      // flex-grow: 1;
      flex-shrink: 0;
      margin: 0px 15px 0px 0;
      .post-form__categories--category-title {
        margin-bottom: 7px;
      }
      .post-form__categories--category-select {
        @include multi-select-main-mixin;
      }
    }

    .post-form__categories--sub-category {
      // flex-grow: 1;
      width: 50%;
      margin: 0px;
      flex-shrink: 0;
      .post-form__categories--sub-category-title {
        margin-bottom: 7px;
      }
      .post-form__categories--sub-category-select {
        @include multi-select-main-mixin;
      }
    }
  }

  .post-form__keywords {
    width: 100%;
    // padding: 10px;

    .post-form__keywords--title {
      margin-bottom: 2px;
    }
    .post-form__keywords--keywords {
      border: 1px solid #b1b1b1;
      padding: 15px 20px 5px 20px;
    }

    p {
      margin-top: 10px;
      color: #2096f3;
      font-size: 12px;
      font-weight: 600;
    }

    .md-chip-list .md-chip {
      background: #2096f3 !important;
      color: #fff !important;
    }

    .md-chip-list .md-chip .close {
      color: #fff !important;
    }
  }

  .post-form__title {
    // padding: 10px;
    .post-form__title--title {
      margin-bottom: 2px;
    }
    .post-form__title--input {
      @include input-text-validation-main;
      .md-form.md-outline {
        margin: 0px !important;
      }
    }
  }

  .post-form__price {
    // padding: 10px;
    width: 100%;
    .post-form__price--title {
      margin-bottom: 2px;
    }

    .post-form__price--input {
      @include input-text-validation-main;
      display: flex;
      align-items: center;
      // flex-wrap: wrap;
      .input-group {
        margin: 0px 15px 20px 15px!important;
      }
    }
  }

  .post-form__description {
    // padding: 10px;
    .post-form__description--title {
      margin-bottom: 2px;
    }
    .post-form__description--textarea {
      @include input-text-validation-main;
      .md-form.md-outline {
        margin: 0 !important;
      }
    }
  }

  .post-form__gallery {
    // padding: 10px;

    .post-form__gallery--title {
      //margin-bottom: 5px;
    }

    .post-form__gallery--text {
      //margin-bottom: 10px;
    }

    .post-form__gallery--thumbs {
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      flex-direction: row;
      justify-content: space-between;
      //    justify-content: space-evenly;

      .post-form__gallery--thumbs-item {
        width: 170px;
        height: 170px;
        margin-bottom: 10px;
        background: #f7f7f7;
        border: 1px solid #e6e6e6;
        border-radius: 4px;
        position: relative;
        padding: 10px;
        display: flex;
        align-items: center;
        justify-content: center;

        app-icon {
          cursor: pointer;
        }

        .featured {
          position: absolute;
          left: 5px;
          bottom: 0;
          width: 100%;
          height: 25px;
          // background: $accent-color;
          font-size: 12px;

          .form-check-input[type='checkbox'] + label,
          label.btn input[type='checkbox'] + label {
            padding-left: 20px;
            line-height: 1rem;
          }

          .form-check-input[type='checkbox'].filled-in:not(:checked) + label:after,
          label.btn input[type='checkbox'].filled-in:not(:checked) + label:after {
            height: 15px;
            width: 15px;
          }

          .form-check-input[type='checkbox'].filled-in:checked + label:before,
          label.btn input[type='checkbox'].filled-in:checked + label:before {
            top: -3px;
            left: 1px;
            width: 6px;
            height: 13px;
          }

          mdb-checkbox.checkbox-primary-filled
            [type='checkbox'][class*='filled-in']:checked
            + label:after {
            border-color: #2096f3;
            background-color: #2096f3;
            // padding-left: 20px;
            height: 15px;
            width: 15px;
          }
        }
      }
    }

    .post-form__gallery--btn {
      display: flex;
      align-items: center;
      justify-content: flex-end;

      .btn.btn-sm {
        font-size: 14px !important;
        color: #2096f3 !important;
        padding: 0px !important;
        margin: 0px 25px 0px 0px !important;
      }
    }
  }

  .post-form__contact-info {
    // padding: 10px;

    .post-form__contact-info--title {
      margin-bottom: 5px;
    }

    .post-form__contact-info--checkbox {
      .form-check-input[type='checkbox'] + label {
        padding-left: 22px !important;
      }
    }

    .post-form__contact-info--input {
      .form-control:focus {
        input {
          outline: none !important;
        }
      }
    }

    mdb-checkbox.checkbox-primary-filled
      [type='checkbox'][class*='filled-in']:checked
      + label:after {
      border-color: #2096f3;
      background-color: #2096f3;
    }
  }

  .post-form__address {
  }

  .link {
    color: $active-color;
    .btn {
      margin: 0px !important;
    }
    .btn-flat {
      margin: 0px !important;
    }

    .btn.btn-flat {
      font-size: 1rem !important;
      padding: 0px !important;
      //margin-left: 23px !important;
    }
  }
  .circle{
  border:1px solid #2096F3;
  width:20px;
  height:20px;
  border-radius:100%;
  position:relative;
  margin:4px;
  display:inline-block;
  vertical-align:middle;
  background-color: #2096F3;
  cursor: pointer;
}

.circle.minus:before{
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background:#FFF;
    margin: auto 2px;
    height: 2px;
}
}

.modal-body.file-upload-modal {
  background: #f7f7f7;
  padding: 0px;
  margin: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  .uploading-files {
    width: 100%;
    height: 48px;
    background: #fff;
    display: flex;
    align-items: center;
    box-sizing: border-box;
    border: #e6e6e6 1px solid;

    .uploading-files__icon {
      margin: 0px 10px;
      flex-shrink: 0;
    }
    .uploading-files__name {
      margin: 0px 10px;
      // flex-shrink: 0;
    }

    .uploading-files__size {
      margin: 0 10px;
    }
    .uploading-files__progress {
      flex-grow: 1;
      mdb-progress .progress {
        margin-bottom: 0px !important;
        height: 15px;

        .progress-bar {
          height: 15px;
        }
      }
    }
    .uploading-files__cancel {
      margin: 0 10px;
      flex-shrink: 0;
    }
  }
}

@include media-breakpoint-down(xs) {
  .post-form {
    margin: 0;
    padding: 0;
    font-size: 13px;
    // font-weight: 300;

    .post-form__kind {
      width: 100%;

      .post-form__kind--title {
        margin-bottom: 10px;
      }

      .mdb-select-toggle {
        font-size: 9px;
      }

      mdb-select > div > div.single {
        height: 16px;
      }

      mdb-select.mdb-select-outline > label {
        font-size: 13px;
      }

      mdb-select.mdb-select-outline > label.active {
        font-size: 11px;
      }

      .dropdown-content li > a,
      .dropdown-content li > span {
        font-size: 0.8rem;
      }

      mdb-select.mdb-select-outline .multiple,
      mdb-select.mdb-select-outline .single {
        font-size: 0.8rem;
      }
    }

    .post-form__categories {
      width: 100%;
      flex-wrap: wrap;
      .post-form__categories--category {
        width: 100%;
        margin: 0;
        padding: 0;

        .post-form__categories--category-title {
          margin-bottom: 10px;
        }

        .mdb-select-toggle {
          font-size: 9px;
        }

        mdb-select > div > div.single {
          height: 16px;
        }

        mdb-select.mdb-select-outline > label {
          font-size: 13px;
        }

        mdb-select.mdb-select-outline > label.active {
          font-size: 11px;
        }

        .dropdown-content li > a,
        .dropdown-content li > span {
          font-size: 0.8rem;
        }

        mdb-select.mdb-select-outline .multiple,
        mdb-select.mdb-select-outline .single {
          font-size: 0.8rem;
        }
      }
      .post-form__categories--sub-category {
        width: 100%;
        margin: 20px 0px 0px 0px;
        padding: 0;

        .post-form__categories--sub-category-title {
          margin-bottom: 10px;
        }

        .mdb-select-toggle {
          font-size: 9px;
        }

        mdb-select > div > div.single {
          height: 0.8rem;
        }

        mdb-select.mdb-select-outline .multiple,
        mdb-select.mdb-select-outline .single {
          font-size: 0.8rem;
        }

        mdb-select.mdb-select-outline > label {
          font-size: 13px;
        }

        mdb-select.mdb-select-outline > label.active {
          font-size: 11px;
        }

        .dropdown-content li > a,
        .dropdown-content li > span {
          font-size: 0.8rem;
        }
      }
    }

    .post-form__price {
      width: 100%;

      .input-group-text {
        font-size: 14px;
      }

      .form-control {
        font-size: 0.8rem;
      }

      .post-form__price--input {
        flex-wrap: wrap;
      }
    }

    .post-form__keywords {
      .post-form__keywords--keywords {
        padding: 10px 5px;
      }

      .md-chip-list .md-chip {
        padding: 5px 12px;
        margin-right: 5px;

        .close {
          font-size: 13px;
        }
      }
    }

    .post-form__title {
      .post-form__title--input {
        .form-control {
          font-size: 13px;
        }
      }
    }

    .post-form__description {
      .post-form__description--title {
        margin-bottom: 10px;
      }
      .post-form__description--textarea {
        .md-form.md-outline label {
          font-size: 0.8rem !important;

          .active {
            font-size: 0.8rem !important;
          }
        }

        .form-control {
          font-size: 0.8rem;
        }
      }
    }

    .post-form__gallery {
      .post-form__gallery--thumbs {
        .post-form__gallery--thumbs-item {
          margin-bottom: 10px;
        }
      }
    }
  }
}

@media (max-width: 768px) {
  .post-form {
    .post-form__address {
      width: 300px;
    }
  }
}

.categories-multi-select {
  // color: black ;
}
