@import '../variables';

$inactive-color: #DEDEDE;
$active-color: #1475FC;
$padding-in-box: 10px;
$yp-padding-in-box: 0px 10px;
$form-check-label-PL: 25px !important;
$select-padding: 20px 20px 10px 23px;
$select-label-bp: 5px;

.Advertiser-information{
    width: 100%;
    margin: 30px 0px 20px 0px;
    display: flex;
    flex-direction: column;
    align-items: stretch;

    .Advertiser-information__personal {
        margin: 0px 0px 10px 0px;
        width: inherit;
        border: solid 1px $inactive-color;
        padding: $yp-padding-in-box;
        display: flex;
        align-items: center;
        flex-wrap: wrap;

        .link {
            color: $active-color;
            a {
                text-decoration: none;
                text-transform: uppercase;
            }

            
        }

        &.active {
            border: solid 1px $active-color;
        }

        .form-check {
            margin: 0 !important;
            padding: 0 !important;

            .form-check-label {
                padding-left: $form-check-label-PL;
            }
        }
    }

    .Advertiser-information__company {
        width: inherit;
        border: solid 1px $inactive-color;
        padding: $padding-in-box;
        display: flex;
        flex-direction: column;

        .link {
            color: $active-color;
            .btn{
                margin: 0px !important;
            }
            .btn-flat{
                margin: 0px !important;
            }

            .btn.btn-flat{
                font-size: $yp-font !important;
                // font-size: 1rem !important;
                padding: 0px !important;
                margin-left: 23px !important;
            }
        }

        &.active {
            border: solid 1px $active-color;
        }

        .form-check {
            margin: 0 !important;
            padding: 0 !important;

            .form-check-label {
                padding-left: $form-check-label-PL;
            }
        }

        .form-radio {
            margin: 0 !important;
            padding: 0 !important;

            .form-check-label {
                padding-left: $form-check-label-PL;
            }
        }


        .company-select {
            padding: $select-padding;
            .company-select__label {
                padding-bottom: $select-label-bp;
            }
        }

        .branch-select {
            padding: $select-padding;
            .branch-select__label {
                padding-bottom: $select-label-bp
            }

            .branch-select__select {
                font-size: $yp-font !important;
            }
        }

    

    }
}

.create-new-company.modal-title{
    text-align: center;
    font-size: $yp-font !important;
    font-weight: bold !important;
}


@include media-breakpoint-down(xs) {
    .Advertiser-information {
        // font-size: 13px;
    }

    ul.stepper .step-new-content{
        margin: 0px !important;
    }

    .custom-select {
        // font-size: 13px !important;
    }
}

@media (max-width: 768px) {
    .Advertiser-information{

        .Advertiser-information__personal {

            .form-check {
                margin: 0.5rem 0rem 0rem 0rem !important;
            }
        }
    }
}