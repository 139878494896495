@import '../variables';
@import '../mixins/preloader';


.claim-company-profile {


    .claim-company-form{

        .preloader{
            @include preloader;
        }

        &.preloader-trigger .preloader{

            @include preloader-trigger;

        }
    }

}