// Templates
// Fixed SideNav
.fixed-sn {
  .double-nav,
  main,
  footer {
    padding-left: $fixed-sn-double-nav-main-footer-pl;
  }
  main {
    padding-top: $fixed-sn-double-nav-main-pt;
  }
  @media (max-width: $sidenav-breakpoint) {
    .double-nav,
    main,
    footer {
      padding-left: 0;
    }
  }
  @media (min-width: $small-screen) {
    main,
    .page-footer .container-fluid {
      margin-left: $fixed-sn-double-nav-main-small-mx;
      margin-right: $fixed-sn-double-nav-main-small-mx;
    }
  }
  @media (min-width: $medium-screen) {
    main,
    .page-footer .container-fluid {
      margin-left: $fixed-sn-double-nav-main-medium-mx;
      margin-right: $fixed-sn-double-nav-main-medium-mx;
    }
  }
  @media (min-width: $large-screen) {
    main,
    .page-footer .container-fluid {
      margin-left: $fixed-sn-double-nav-main-large-mx;
      margin-right: $fixed-sn-double-nav-main-large-mx;
    }
  }
}

// Hidden SideNav
.hidden-sn {
  main {
    padding-top: $hidden-sn-main-pt;
  }
  .button-collapse {
    display: block;
    position: relative;
    font-size: $hidden-sn-main-btn-collapse-font-size;
    margin-right: $hidden-sn-main-btn-collapse-mr;
    margin-left: $hidden-sn-main-btn-collapse-ml;
    padding-left: 0;
  }
}
