@import '../variables';

.advertisement-plans {

    // width: 90%;
    // margin: 10px auto;
    // background: lightblue;
    margin: 40px 0px;

    .advertisement-plans__company {}

    .advertisement-plans__individual {

        .advertisement-plans__individual--tabs {
            // background: lightcoral;
           
            border-bottom: 2px solid #D8D8D8;
            
            

            a{
                color: #909090;
                font-weight: normal;

                transition: all ease-in-out;
            }

            .active {
                border-left: 1px solid #D8D8D8;
                border-right: 1px solid #D8D8D8;
                border-top: 3px solid #2096F3;
                box-sizing: border-box;
                outline: none;
                a{
                    color: #000;
                    font-size: 16px;
                    font-weight: bold;
                }

                transition: all ease-in-out;
            }

        }

        .advertisement-plans__individual--body {
            // background: lightgreen;
            box-shadow: none !important;
            border: none !important;
            padding-bottom: 100px;

            mdb-tab.active{
                display: inherit;
                align-items: center;
                justify-content: center;
                flex-wrap: wrap;
            }

    
            mdb-tab.pay-by-post-content.active{
                display: block !important;
                // align-items: center;
                // justify-content: center;
                // flex-wrap: wrap;
                h5{
                    font-size: 16px;
                    font-weight: bold;
                    margin-bottom: 10px;
                }
            }

            // .tab-content > .active{
            //     display: flex;

            // }
        
        }
    }
}
