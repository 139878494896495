@import '../variables';
@import '../mixins/direction';

@import '../variables';
@import '../mixins/direction';


.home-layout {
    width: 100%;
    height: 100%;
    padding: 0;
    margin: 0;
}

.home-layout__row {
    display: flex;
    // align-items: center;
}

.home-layout__row--left {
    width: 300px;
    flex-shrink: 0;
    height: 100%;
    margin-top: 10px;

    svg {
        fill: #5d5d5d;
    }
}

.home-layout__row--right {
    // background: #fff;
    // padding: 30px;
    margin: 10px;
    width: 100%;
    height: 100%;
    // border-radius: 15px;
    // box-shadow: 6px 6px 10px rgba($color: #000, $alpha: .2);
    // height: 500px;
}

@include media-breakpoint-down(lg) {
    .home-layout__row--left{
        width: 100px;
        // background: black;
        
        // svg{
        //     fill: $accent-color;
        // }
    }
}