@import '../variables';

.pricing-by-post {

   display: block !important;
//    flex-direction: column;
//    align-items: center;
//    background: chartreuse;

    .pricing-by-post__item {

        width: 100%;
        margin-bottom: 10px;
        //border: 1px solid #DEDEDE;
        padding: 10px 0px;
        transition: border-color .1s ease-in-out;

        &.active {
            border: 1px solid #1475FC;
        }

        .pricing-by-post__item-radio{

            .form-check{

                .form-check-input{}

                .form-check-label{
                    display: flex !important;
                    
                    .label{
                        // flex-grow: 1;
                    }
                    
                    .pricing-by-post__item-title--link {
                     color: #1475FC;
                     font-weight: 500;
                    }
                }
            }

        }

        .pricing-by-post__item-details{
            margin-left: 54px;
        }
    }

}
// @media (min-width: 768px) {
//     .pricing-by-post {
//         margin-left: 20%;
//         margin-right: 20%;
//     }
// }