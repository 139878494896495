@import '../variables';
@import '../mixins/direction';


.indicator {
    display: flex;
    color: $indicator-default-font-color;
    // margin-right: 20px;
} 

.indicator--mobile-search {} 
.indicator--mobile{}

.indicator__button {
    display: flex;
    align-items: center;
    border: none;
    background: transparent;
    cursor: pointer;
    padding: 0;
    color: inherit;
    fill: currentColor;

    &:focus {
        outline: none;
    }
    &:hover {
        color: inherit;
    }
}

.indicator__area {
    background: $indicator-default-bg;
    transition: background-color .2s;
    border-radius: $indicator-border-radius;
    // height: $indicator-height;
    padding: 0 14px;
    display: flex;
    align-items: center;
}