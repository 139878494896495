@import '../variables';
.side-nav-link-item {
    background: transparent;
    .side-nav-link-item__head {
        display: flex !important;
        align-items: center !important;
        // justify-content: space-around !important;
        h5 {
            font-size: .9rem !important;
            font-weight: $font-weight-medium !important;
            color: $light-opposite-color !important;
        }
        svg {
            display: inline !important;
            padding: 0 !important;
            margin: 0 !important;
            fill: $light-opposite-color !important;
        }
    }
    a.side-nav-link-item__active {
        .side-nav-link-item__head {
            // display: flex !important;
            // align-items: center !important;
            background: #2096F3 !important;
            border-radius: 0 50px 50px 0;
            h5 {
                font-size: 14px !important;
                font-weight: $font-weight-medium !important;
                color: #fff !important;
            }
            svg path {
                fill: #fff !important;
            }
        }
    }
}

@media only screen and (max-width: 600px) {
    a.side-nav-link-item__active {
        .side-nav-link-item__head {
            // display: flex !important;
            // align-items: center !important;
            background: #2096F3 !important;
            border-radius: 0 50px 50px 0;
            h5 {
                font-size: 14px !important;
                font-weight: $font-weight-medium !important;
                color: #fff !important;
            }
            svg path {
                fill: #fff !important;
            }
        }
    }
}