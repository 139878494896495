@import '../variables';
@import '../mixins/preloader';

.create-new-company{
    
    .create-new-company__body{
       
        .create-new-company__body-preloader{
            @include preloader;

            .spinner-container{
                @include preloader-container
            }

        }

        &.create-new-company__body-loading .create-new-company__body-preloader{
            @include preloader-trigger;
        }

       
    }

  
}