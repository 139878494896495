@import '~ng-uikit-pro-standard/assets/scss/core/colors';
@import '~ng-uikit-pro-standard/assets/scss/core/variables';
@import '~ng-uikit-pro-standard/assets/scss/core/variables-pro';
@import '~ng-uikit-pro-standard/assets/scss/bootstrap/functions';
@import '~ng-uikit-pro-standard/assets/scss/bootstrap/variables';
@import '~ng-uikit-pro-standard/assets/scss/core/mixins';


// ----------------------------------------
// -- direction
// ----------------------------------------
$direction:                 rtl                                      !default; // or rtl
$both-directions:           true                                     !default;


$inline-start:              if($direction == ltr, left, right)       !default;
$inline-end:                if($direction == ltr, right, left)       !default;
$transform-direction:       if($direction == ltr, 1, -1)             !default;

$margin-inline-start:       margin- + $inline-start                  !default;
$margin-inline-end:         margin- + $inline-end                    !default;
$padding-inline-start:      padding- + $inline-start                 !default;
$padding-inline-end:        padding- + $inline-end                   !default;
$inset-inline-start:        $inline-start                            !default;
$inset-inline-end:          $inline-end                              !default;
$border-inline-start:       border- + $inline-start                  !default;
$border-inline-end:         border- + $inline-end                    !default;
$border-start-start-radius: border-top- + $inline-start + -radius    !default;
$border-start-end-radius:   border-top- + $inline-end + -radius      !default;
$border-end-start-radius:   border-bottom- + $inline-start + -radius !default;
$border-end-end-radius:     border-bottom- + $inline-end + -radius   !default;


// ----------------------------------------
// -- colors
// ----------------------------------------
// light
$light-color:                    #F0F0F0                  !default;
$light-opposite-color:           #3d464d               !default;
$light-opposite-muted-color:     #A2A2A2                  !default;
$light-opposite-alt-color:       #6c757d               !default;
$light-divider-color:            #ebebeb               !default;
// dark
$dark-color:                     $light-opposite-color !default;
$dark-opposite-color:            $light-color          !default;
// accent
$accent-color:                   #FFE633               !default;
$accent-opposite-color:          $light-opposite-color !default;
//links
$link-color:                     #1a66ff               !default;
$link-hover-color:               #1a66ff               !default;


// ----------------------------------------
// -- helpers
// ----------------------------------------
// Font Weight
$font-weight-thin:        100 !default;
$font-weight-extra-light: 200 !default;
$font-weight-light:       300 !default;
$font-weight-normal:      400 !default;
$font-weight-medium:      500 !default;
$font-weight-semi-bold:   600 !default;
$font-weight-bold:        700 !default;
$font-weight-extra-bold:  800 !default;
$font-weight-black:       900 !default;
// breakpoints
$breakpoints: (
    xs-start: 0px,
    xs-end:   575px,
    sm-start: 576px,
    sm-end:   767px,
    md-start: 768px,
    md-end:   991px,
    lg-start: 992px,
    lg-end:   1199px,
    xl-start: 1200px
);


// ----------------------------------------
// -- body
// ----------------------------------------
$yp-font:               14px                        !default;
$yp-family:             'Roboto'                    !default;
$body-bg:               $light-color                !default;
$body-font-color:       $light-opposite-color       !default;
$body-font-muted-color: $light-opposite-muted-color !default;
$body-font-alt-color:   $light-opposite-alt-color   !default;
// $body-font-family:      'Roboto', 'sans-serif'   !default;
$body-font-family:      $yp-family                  !default;
$body-font-size:        16px                        !default;
$body-font-weight:      $font-weight-normal         !default;
$body-line-height:      1.5                         !default;
$body-divider-color:    $light-divider-color        !default;


// ----------------------------------------
// -- header
// ----------------------------------------
$header-height:             70px                    !default;   
$header-bg:                 $accent-color           !default;
$header-font-color:         inherit                 !default;
$header-font-muted-color:   $body-font-muted-color  !default;
$header-logo-color:         inherit                 !default;
$header-search-margin-left: 10px                    !default;
$header-link-margin:        0px 3px 0px 15px        !default;
$header-link-padding:       0px 3px 0px 15px        !default;
$header-link-font-size:     1rem                    !default;
$header-icon-margin:        0px 5px                 !default;
$header-icon-padding:       10px                    !default;
$header-counter-background: #FD9D27               !default;
$header-counter-color:      #fff                  !default;
$header-counter-size:        17px                   !default;
$header-counter-padding:     2px                    !default;
$header-counter-font-size:   11px                   !default;
$header-user-avatar-margin:  0px 20px 0px 10px      !default;
$header-user-avatar-padding: 0px 20px 0px 0px       !default;       

    // ----------------------------------------
   // -- .search
   // ----------------------------------------
   $search-height:                         42px                                   !default;
   $search-border-radius:                  3px                                    !default;
   // input default
   $search-input-default-bg:               #fff                            !default;
   $search-input-default-shadow:           0 0 0 2px $accent-color inset                !default;
   $search-input-default-placeholder:      #999                                   !default;
   $search-input-default-font-color:       inherit                                !default;
   $search-input-default-icon-color:       #bfbfbf                                !default;
   $search-input-default-icon-hover-color: #000                          !default;
   // input hover
   $search-input-hover-bg:                 $search-input-default-bg               !default;
   $search-input-hover-shadow:             0 0 0 2px $accent-color inset                !default;
   $search-input-hover-placeholder:        $search-input-default-placeholder      !default;
   $search-input-hover-font-color:         $search-input-default-font-color       !default;
   $search-input-hover-icon-color:         $search-input-default-icon-color       !default;
   $search-input-hover-icon-hover-color:   $search-input-default-icon-hover-color !default;
   // input hover
   $search-input-focus-bg:                 $search-input-hover-bg                 !default;
   $search-input-focus-shadow:             0 0 0 2px $accent-color inset          !default;
   $search-input-focus-placeholder:        $search-input-hover-placeholder        !default;
   $search-input-focus-font-color:         $search-input-hover-font-color         !default;
   $search-input-focus-icon-color:         $search-input-hover-icon-color         !default;
   $search-input-focus-icon-hover-color:   $search-input-hover-icon-hover-color   !default;

    // ----------------------------------------
   // -- .nav-panel, .mobile-header
   // ----------------------------------------
   $nav-panel-bg:           $accent-color                                                                           !default;
   $nav-panel-shadow:       0 1px rgba(#000, .04), inset 0 -1px rgba(#000, .04)                                 !default;
   $nav-panel-stuck-shadow: 0 1px rgba(#000, .02), inset 0 -1px rgba(#000, .03), 0 1px 20px rgba(#000, .15)   !default;
   $nav-panel-font-color:   $accent-opposite-color                                                                  !default;
   $nav-panel-logo-color:   $nav-panel-font-color                                                                   !default;
   $nav-panel-height:       54px                                                                                    !default;
   $nav-panel-padding:      6px                                                                                     !default;
    // ----------------------------------------
   // -- .mobile-header
   // ----------------------------------------
    // menu button
    $mobile-header-menu-button-border-radius:             2px                                                  !default;
    $mobile-header-menu-button-default-bg:                transparent                                          !default;
    $mobile-header-menu-button-default-color:             $nav-panel-font-color                                !default;
    $mobile-header-menu-button-hover-bg:                  rgba(#fff, .5)                                     !default;
    $mobile-header-menu-button-hover-color:               $mobile-header-menu-button-default-color             !default;

    // ----------------------------------------
   // -- .nav-links
   // ----------------------------------------
   $nav-links-item-height:              $nav-panel-height - $nav-panel-padding * 2 !default;
   $nav-links-item-border-radius:       2px                                        !default;
   $nav-links-item-font-weight:         $font-weight-bold                          !default;
   // default
   $nav-links-item-default-bg:          transparent                                !default;
   $nav-links-item-default-shadow:      none                                       !default;
   $nav-links-item-default-font-color:  inherit                                    !default;
   $nav-links-item-default-arrow-color: rgba(#000, .3)                             !default;
   // hover
   $nav-links-item-hover-bg:            rgba(#fff, .5)                             !default;
   $nav-links-item-hover-shadow:        $nav-links-item-default-shadow             !default;
   $nav-links-item-hover-font-color:    $nav-links-item-default-font-color         !default;
   $nav-links-item-hover-arrow-color:   $nav-links-item-default-arrow-color        !default;


    // ----------------------------------------
   // -- .indicator
   // ----------------------------------------
   $indicator-height:                     $nav-links-item-height                !default;
   $indicator-border-radius:              $nav-links-item-border-radius         !default;
   // default
   $indicator-default-bg:                 $nav-links-item-default-bg            !default;
   $indicator-default-font-color:         $nav-links-item-default-shadow        !default;
   $indicator-default-counter-bg:         $light-color                          !default;
   $indicator-default-counter-font-color: $light-opposite-color                 !default;
   // hover
   $indicator-hover-bg:                   $nav-links-item-hover-bg              !default;
   $indicator-hover-font-color:           $indicator-default-font-color         !default;
   $indicator-hover-counter-bg:           $indicator-default-counter-bg         !default;
   $indicator-hover-counter-font-color:   $indicator-default-counter-font-color !default;



// ----------------------------------------
// -- common
// ----------------------------------------
   // ----------------------------------------
   // -- headers
   // ----------------------------------------
   $headers-font-family: null                 !default;
   $headers-font-weight: $font-weight-bold    !default;
   $headers-font-style:  null                 !default;
   // h1
   $h1-font-family:      $headers-font-family !default;
   $h1-font-size:        36px                 !default;
   $h1-font-weight:      $headers-font-weight !default;
   $h1-font-style:       $headers-font-style  !default;
   // h2
   $h2-font-family:      $headers-font-family !default;
   $h2-font-size:        32px                 !default;
   $h2-font-weight:      $headers-font-weight !default;
   $h2-font-style:       $headers-font-style  !default;
   // h3
   $h3-font-family:      $headers-font-family !default;
   $h3-font-size:        28px                 !default;
   $h3-font-weight:      $headers-font-weight !default;
   $h3-font-style:       $headers-font-style  !default;
   // h4
   $h4-font-family:      $headers-font-family !default;
   $h4-font-size:        24px                 !default;
   $h4-font-weight:      $headers-font-weight !default;
   $h4-font-style:       $headers-font-style  !default;
   // h5
   $h5-font-family:      $headers-font-family !default;
   $h5-font-size:        20px                 !default;
   $h5-font-weight:      $headers-font-weight !default;
   $h5-font-style:       $headers-font-style  !default;
   // h6
   $h6-font-family:      $headers-font-family !default;
   $h6-font-size:        16px                 !default;
   $h6-font-weight:      $headers-font-weight !default;
   $h6-font-style:       $headers-font-style  !default;


//    Preloader
$preloader-thickness: 2px            !default;
$preloader-color-1:   rgba(#000, .1) !default;
$preloader-color-2:   rgba(#000, .5) !default;