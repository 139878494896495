@import '../variables';

@mixin input-text-validation-main {

    .md-outline input.form-control.validate-error.ng-invalid.ng-touched, .md-outline input.form-control.validate-error.ng-invalid.ng-dirty, .md-outline.form-submitted input.form-control.validate-error.ng-invalid{
        box-shadow: inset 0 0 0 0px #f44336 !important;
    }

    .md-outline input.form-control.validate-success.ng-valid.ng-dirty, .md-outline input.form-control.validate-success.ng-valid.ng-touched, .md-outline.form-submitted input.form-control.validate-success.ng-valid{
        box-shadow: inset 0 0 0 0px #00c851 !important;
    }

    .md-textarea.validate-error.ng-invalid.ng-touched, .md-textarea.validate-error.ng-invalid.ng-dirty, .form-submitted .md-textarea.validate-error.ng-invalid{
        box-shadow: inset 0 0 0 0px #f44336 !important;
    }

    .md-outline .md-textarea.validate-success.ng-valid.ng-dirty, .md-outline .md-textarea.validate-success.ng-valid.ng-touched{
        box-shadow: inset 0 0 0 0px #00c851 !important;

    }
    
}